import tw, { styled } from 'twin.macro';
import { BREAKPOINTS } from '../../constants';

type FancyProps = {
  isHomepage: boolean;
};

export const PageIntro = styled.div<FancyProps>(({ isHomepage }) => {
  const out = [
    tw`inline-block text-center relative z-50 my-0 mx-auto border-white border-2 border-solid bg-blue-seethru w-full`,
    {
      minWidth: 50,
      maxWidth: '35rem',
      maxHeight: '11.3rem',
      top: isHomepage ? '-7.8rem' : '-11rem',
      '&:after': [
        tw`bg-white block absolute`,
        {
          content: "''",
          width: 2,
          left: 'calc(50% - 1px)',
          transform: 'translateX(-50%)',
          height: 67,
        },
      ],
      [`@media screen and (max-width: ${BREAKPOINTS.lg}px)`]: [
        {
          top: isHomepage ? '-7.1rem' : '-14rem',
        },
      ],
      [`@media screen and (max-width: ${BREAKPOINTS.md}px)`]: [
        {
          top: isHomepage ? '-5.1rem' : '-5rem',
          '&:after': {
            height: 31,
          },
        },
      ],
      [`@media screen and (max-width: ${BREAKPOINTS.sm}px)`]: [
        tw``,
        {
          maxWidth: '300px',
          top: isHomepage ?'-6.5rem': '-5rem',
          bottom: isHomepage ? '110px' : 'auto',
          '&:after': { height: 30 },
        },
      ],
    },
  ];
  return out;
});

export const Title = styled.h1(
  () =>
    tw`text-white font-primary leading-normal text-base lg:text-xl align-middle uppercase lg:leading-8 bg-transparent px-4 lg:px-6 py-3 w-auto`,
);
